@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
          url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?qh450s1') format('woff2'),
          url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?qh450s1') format('truetype'),
          url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?qh450s1') format('woff'),
          url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?qh4501s##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icomoon-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-timer {
  &:before {
    content: $icon-timer;
  }
}
.icon-clock {
  &:before {
    content: $icon-clock;
  }
}
.icon-snapchat {
  &:before {
    content: $icon-snapchat;
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube;
  }
}
.icon-stats-growth {
  &:before {
    content: $icon-stats-growth;
  }
}
.icon-stats-bars {
  &:before {
    content: $icon-stats-bars;
  }
}
.icon-pie-chart {
  &:before {
    content: $icon-pie-chart;
  }
}
.icon-tag {
  &:before {
    content: $icon-tag;
  }
}
.icon-whatsapp {
  &:before {
    content: $icon-whatsapp;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down;
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up;
  }
}
.icon-magnifier {
  &:before {
    content: $icon-magnifier;
  }
}
.icon-circle {
  &:before {
    content: $icon-circle;
  }
}
.icon-pinterest {
  &:before {
    content: $icon-pinterest;
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin;
  }
}
.icon-google-plus {
  &:before {
    content: $icon-google-plus;
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram;
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}

