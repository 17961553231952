@media only screen {
  .no-spacer {
    margin: 0 !important;
  }
  .no-top-spacer {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
  .no-bottom-spacer {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
  .no-left-spacer {
    margin-#{$default-direction}: 0 !important;
    padding-#{$default-direction}: 0 !important;
  }
  .no-right-spacer {
    margin-#{$opposite-direction}: 0 !important;
    padding-#{$opposite-direction}: 0 !important;
  }
  .top-spacer--xl {
    margin-top: 3rem !important;
  }
  .top-spacer--l {
    margin-top: 2.5rem !important;
  }
  .top-spacer--m {
    margin-top: 2rem !important;
  }
  .top-spacer {
    margin-top: 1.5rem !important;
  }
  .top-spacer--s {
    margin-top: 1rem !important;
  }
  .top-spacer--xs {
    margin-top: 0.5rem !important;
  }
  .bottom-spacer--xl {
    margin-bottom: 3rem !important;
  }
  .bottom-spacer--l {
    margin-bottom: 2.5rem !important;
  }
  .bottom-spacer--m {
    margin-bottom: 2rem !important;
  }
  .bottom-spacer {
    margin-bottom: 1.5rem !important;
  }
  .bottom-spacer--s {
    margin-bottom: 1rem !important;
  }
  .bottom-spacer--xs {
    margin-bottom: 0.5rem !important;
  }
}

/*html, body {
  height: auto;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;;
}*/

/* Drupal admin styles ----- START -----*/

.drupal-tabs ul.tabs {
  list-style: none;
  margin-#{$opposite-direction}: 0;
  li {
    background-color: #f8f8f8;
    border-radius: 5px;
    display: inline-block;
    margin: 0 5px 8px;
    padding: 5px 15px;

    &:hover,
    &.active {
      background: $primary-color;
      a {
        color: white;
      }
    }
  }
}

/* Drupal admin styles ----- END -----*/

.border-right {
  border-right: 1px solid $gray;
}

.large-up-border-right {
  @include breakpoint(large) {
    border-right: 1px solid $gray;
  }
}

.large-up-border-left {
  @include breakpoint(large) {
    border-left: 1px solid $gray;
  }
}

.border-bottom {
  border-bottom: 1px solid $gray;
}

.border-left {
  border-#{$default-direction}: 1px solid $gray;
}

.space-top {
  margin-top: 0.8rem;
}

.padding-bottom {
  padding-bottom: 1.5rem;
}

.vertical-center {
  @include vertical-center();
}

.use-loader {
  height: 350px;
  overflow: hidden;
  background: url(../img/ajax-loader.gif) no-repeat center center transparent;
  > div {
    opacity: 0;
    visibility: hidden;
    height: 400px;
  }
}

.use-loader.slick-initialized.slick-loaded {
  background: none;
  height: auto;
  overflow: visible;
  > div {
    height: auto;
  }
  &.slider-nav {
    background: $black;
  }
}

.use-loader.slick-initialized.slick-loaded .slick-list,
.use-loader.slick-initialized.slick-loaded button {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.25s ease-in;
}

/*.radius {
  border-radius: 3px;
}*/

.round {
  border-radius: 1000px;
  overflow: hidden;
}

.input-group.with-radius > *:first-child {
  border-radius: 0 5px 5px 0;
}

.input-group.with-radius > *:last-child > * {
  border-radius: 5px 0 0 5px;
}

textarea {
  height: 7rem;
}

.arial-heading {
  font-family: Arial, sans-serif;
}

a {
  transition:
    color 0.25s ease 0s,
    background-color 0.25s ease 0s,
    opacity 0.25s ease 0s;
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
}

.off-canvas {
  height: 100%;
}

.js-off-canvas-exit {
  z-index: 10;
}

[dir="rtl"] .close-button {
  #{$opposite-direction}: auto;
  #{$default-direction}: 1rem;
}

.placeholder {
  font-size: 0.875rem;
  color: #aaa;
}

#main-wrap p {
  font-size: 1.125rem;
  margin-bottom: 1rem;
  text-align: #{$default-direction};
}

figure {
  margin: 0 0 5px;
}

.leaderboard-ad {
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
}

button,
.button {
  font-family: $font-bold;
  &.secondary:hover {
    background-color: $primary-color;
  }
}

.maxWidth-medium {
  max-width: 760px;
  margin-#{$default-direction}: auto;
  margin-#{$opposite-direction}: auto;
  float: none;
}

.maxWidth-small {
  max-width: 660px;
  margin-#{$default-direction}: auto;
  margin-#{$opposite-direction}: auto;
  float: none;
}

.maxWidth-xsmall {
  max-width: 360px;
  margin-#{$default-direction}: auto;
  margin-#{$opposite-direction}: auto;
  float: none;
}

.element-invisible {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
}

main {
  margin-bottom: 3rem;
  .front & {
    padding: 1rem 0;
  }
}

.top-header {
  padding: 1rem 0;
  position: relative;
  @include breakpoint(large) {
    padding: 1.5rem 0;
  }
}

.shifted-sidebar {
  @include breakpoint(large) {
    margin-#{$default-direction}: -15px;
    position: relative;
    .block-wrapper {
      padding-#{$default-direction}: 15px;
    }
  }
}

.logo {
  text-align: center;
  img {
    width: 130px;
    @include breakpoint(large) {
      width: auto;
    }
  }
}

.bottom-header-inner {
  border-bottom: 1px solid $gray;
}

.nav-container {
  float: #{$default-direction};
  ul {
    line-height: normal;
    list-style: none;
    margin: 0;
    font-family: $font-alt;
    &.menu {
      height: 34px;
      > li {
        float: #{$default-direction};
        //position: relative;
        padding-bottom: 1.25rem;
        &:first-child {
          a {
            padding-#{$default-direction}: 0;
          }
        }
        > a {
          color: $black;
          display: block;
          text-transform: uppercase;
          line-height: 1;
          padding: 0 0.5rem;
          border-#{$opposite-direction}: 1px solid $medium-gray;
          font-size: rem-calc(13);
          letter-spacing: 3px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          &:hover {
            color: $primary-color;
          }
          @include breakpoint(1120px) {
            padding: 0 1rem;
            font-size: rem-calc(14);
          }
        }
        &.active-trail,
        &.active {
          > a {
            background: none;
            color: $primary-color;
          }
        }
        &.has-submenu {
          &:hover {
            > .mega-menu {
              visibility: visible;
              opacity: 1;
            }
          }
          > a {
            position: relative;
            padding-#{$opposite-direction}: 2rem;
            &:after {
              display: block;
              content: "\e902";
              font-family: "icomoon";
              font-size: 11px;
              position: absolute;
              #{$opposite-direction}: 0.5rem;
              bottom: 1px;
            }
          }
        }
      }
    }
    ul {
      //transition: opacity .25s ease-in;
      /*visibility: hidden;
      opacity: 0;*/
      //min-width: 160px;
      //padding: 0.5rem 1rem;
      //background-color: $white;
      //position: absolute;
      //top: 1.5rem;
      //#{$default-direction}: 0;
      //z-index: 2;
      border-bottom: 0;
      li {
        a {
          border-bottom: 1px solid $secondary-color;
          display: block;
          text-transform: capitalize;
          padding: 0.75rem 0.5rem;
          font-size: rem-calc(14);
        }
        &.active-trail,
        &.active {
          > a {
            background: none;
            color: $primary-color;
          }
        }
      }
    }
  }
}

.mega-menu {
  font-family: $font-bold;
  transition: all 0.3s linear 0s;
  visibility: hidden;
  opacity: 0;
  min-width: 160px;
  padding: 1rem 1rem 0;
  background-color: $white;
  position: absolute;
  top: 1.5rem;
  #{$default-direction}: 0;
  z-index: 2;
  border-bottom: 3px solid $primary-color;
  width: 100%;
  -webkit-box-shadow: 0 2px 8px 0 $gray;
  -moz-box-shadow: 0 2px 8px 0 $gray;
  box-shadow: 0 2px 8px 0 $gray;
}

.menu-panel {
  font-family: $font-bold;
  font-size: 1.125rem;
  li {
    border-bottom: 1px solid $secondary-color;
    a {
      font-size: 1.5rem;
      padding: 1rem 1.25rem;
      img {
        margin-#{$default-direction}: 0;
        margin-#{$opposite-direction}: 0.625rem;
      }
    }
    &.active {
      > a {
        background: none;
        color: $primary-color;
      }
    }
  }
  ul {
    background: $white;
    border-#{$opposite-direction}: 2px solid #969696;
    font-size: 1rem;
    &.nested {
      margin-#{$default-direction}: 1rem;
    }
    li {
      font-family: $font-bold;
      a {
        font-size: 1.25rem;
        padding: 0.75rem 1.25rem;
      }
    }
  }
}

.menu-panel .is-accordion-submenu-parent > a:after {
  display: none;
}

.menu-panel .is-accordion-submenu-parent {
  > a:before {
    content: "+";
    font-family: tahoma;
    font-size: 1.25rem;
    #{$opposite-direction}: 1rem;
    line-height: inherit;
    margin-top: -0.75rem;
    position: absolute;
    top: 50%;
  }
  &[aria-expanded="true"] > a:before {
    content: "–";
  }
}

.site-header {
  &.sticky {
    background: $white;
    border-bottom: 1px solid $light-gray;
    box-shadow: 0 1px 10px 0 $gray;
    z-index: 999;
  }
}

#sticky-header {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: -100px;
  width: 100%;
  z-index: 100;
  transition: all 0.4s ease 0s;
  //min-height: 50px;
  .bottom-header-inner {
    border-bottom: 0;
  }
  .nav-container ul.menu {
    height: auto;
    > li {
      padding-bottom: 1.25rem;
    }
  }
  &.stuck-header {
    visibility: visible;
    opacity: 1;
    top: 0;
    display: block;
    background: $white;
    border-bottom: 1px solid $light-gray;
    box-shadow: 0 1px 10px 0 $gray;
    .nav-container {
      display: none;
    }
    @include breakpoint(large) {
      padding-top: 1.25rem;
      .nav-container {
        display: block;
      }
      .top-header {
        display: none;
      }
    }
  }

  // for leaderboard
  &.stuck-header-ad {
    position: relative;
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

.sticky-header-spacer {
  display: none;
  height: auto;
  visibility: hidden;
}

.search-btn-holder {
  #{$opposite-direction}: 10px;
  @include breakpoint(medium) {
    #{$opposite-direction}: 15px;
  }
}

.search-btn {
  float: #{$opposite-direction};
  font-family: $font-alt;
  font-size: 14px;
  color: $medium-gray;
  cursor: pointer;
  letter-spacing: 3px;
  position: relative;
  &.opened {
    color: $primary-color;
    i {
      color: $primary-color;
    }
  }
  @include breakpoint(large) {
    top: -4px;
  }
  span {
    padding-bottom: 3px;
  }
  i {
    color: $gray;
    font-size: 1.125rem;
    .top-header & {
      color: $black;
      font-size: 1.5rem;
    }
  }
}

.bottom-header {
  &.sticky {
    padding-top: 1.25rem;
  }
}
.bottom-header-inner {
  position: relative;
}

.search-area {
  display: none;
  background-color: $white;
  border-bottom: 1px solid $gray;
  padding: 1rem 0;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 1;
  box-shadow: 0 10px 5px 5px white;
  @include breakpoint(large) {
    top: 35px;
  }
  .search-area-inner {
    position: relative;
  }
  input[type="text"] {
    color: $black;
    font-size: 1.25rem;
    border: 0;
    width: 100%;
    padding: 0 3rem;
    box-shadow: 0 0 0 0;
    height: 40px;
    line-height: 40px;
    margin: 0;
    @include breakpoint(medium) {
      font-size: 2rem;
    }
  }
  .clear-btn {
    cursor: pointer;
    width: 20px;
    text-align: #{$default-direction};
    font-family: Arial;
    font-size: 2rem;
    position: absolute;
    #{$default-direction}: 0;
    top: 0;
    color: #dedede;
    margin-#{$opposite-direction}: 0.5rem;
    height: 40px;
    line-height: 40px;
    &:hover {
      color: $primary-color;
    }
  }
  input[type="submit"] {
    position: absolute;
    #{$opposite-direction}: 0;
    top: 0;
    font-family: "icomoon";
    color: $black;
    font-size: 2rem;
    background: none;
    height: 40px;
    line-height: 40px;
    border: 0;
    &:hover {
      color: $primary-color;
    }
  }
}

.hero-area {
}

.slideshow-wrapper {
  max-width: 760px;
  position: relative;
  margin: 0 auto 1.5rem;

  .slideshow-item {
    img {
      @include breakpoint(large) {
        height: 540px;
        object-fit: cover;
      }
    }
  }
}

.slideshow-item {
  position: relative;
}

.caption-wrapper {
  position: relative;
  @include breakpoint(medium) {
    background: transparent;
    position: absolute;
    width: 100%;
    bottom: 0;
    #{$opposite-direction}: 0;
    #{$default-direction}: 0;
    margin: 0 auto;
  }
}

.caption {
  background: $black;
  padding: 1rem;
  position: relative;
  z-index: 0;
  //text-transform: uppercase;
  @include breakpoint(medium) {
    background: rgba(0, 0, 0, 0.6);
  }
  h2 {
    font-family: $font-bold;
    margin: 0;
    font-size: 1.5rem;
    @include breakpoint(1150px) {
      font-size: rem-calc(52);
    }
    @include breakpoint(medium) {
      font-size: 2rem;
    }
  }
  a {
    color: $white;
    &:hover {
      color: $primary-color;
    }
  }
}

.slideshow-footer {
  position: absolute;
  bottom: 0;
  #{$opposite-direction}: 0;
}

.slick-dotted.slideshow {
  margin: 0;
}

.slideshow-pager {
  .slick-dots {
    position: relative;
    bottom: auto;
    #{$default-direction}: auto;
    line-height: 1;
    list-style: none;
    margin: 0;
    padding: 13px 8px;
    #{$opposite-direction}: auto;
    text-align: #{$opposite-direction};
    width: 100%;
    li {
      cursor: pointer;
      display: inline-block;
      height: auto;
      margin: 0 3px;
      padding: 0;
      position: relative;
      vertical-align: middle;
      width: auto;
      @include breakpoint(medium) {
        margin: 0 3px;
      }
      i {
        font-size: 0.5rem;
        color: $white;
        cursor: pointer;
        &:hover {
          color: $primary-color;
        }
      }
      &.slick-active {
        i {
          background: none;
          color: $primary-color;
        }
      }
    }
  }
}

.objects-list {
  .media-object-title {
    h4,
    h5,
    h6 {
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
      line-height: 1.3;
      a {
        color: #444444;
        &:hover {
          color: $primary-color;
        }
      }
    }
    h5 {
      font-size: rem-calc(15);
    }
  }
  .media-object-section {
    @media (max-width: 480px) {
      text-align: center;
      display: block;
      padding: 0 0 0.5rem;
    }
  }
  .media-object {
    margin-bottom: 1.5rem;
    .hero-area & {
      margin-bottom: 2.125rem;
    }
  }
  @include breakpoint(medium only) {
    max-width: 70%;
    margin-#{$default-direction}: auto;
    margin-#{$opposite-direction}: auto;
  }
}

.media-object-category {
  color: $primary-color;
  a {
    color: $primary-color;
    &:hover {
      color: $black;
    }
  }
}

.time-ago-article {
  color: $medium-gray;
  .articles-list &,
  .articles-grid & {
    font-size: 1.25rem;
  }
  .objects-list & {
    margin-bottom: 0.5rem;
    font-family: $font-bold;
    line-height: 12px;
  }
}

.article-cat {
  h4 {
    font-size: rem-calc(29);
    //text-transform: uppercase;
    margin: 0;
    a {
      line-height: 2rem;
      display: block;
    }
  }
}

.article-title {
  a {
    display: block;
  }
  .articles-grid & {
    h4,
    h5,
    h6 {
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
      line-height: 1.3;
      a {
        color: #444444;
        &:hover {
          color: $primary-color;
        }
      }
    }
    h5 {
      font-size: rem-calc(15);
    }
  }
}

.section-title {
  position: relative;
  margin-bottom: 1rem;
  &:not([class*="text-"]) {
    text-align: center;
  }
  h1,
  h2,
  h3 {
    margin: 0;
  }
  &.top-border-style {
    padding-top: 1.5rem;
    &:before {
      display: block;
      content: "";
      position: absolute;
      #{$opposite-direction}: 0;
      #{$default-direction}: 0;
      top: 0;
      height: 4px;
      background: $black;
      width: 118px;
      margin: 0 auto;
    }
  }
}

.articles-list {
  .article-item {
    max-width: 360px;
    margin-#{$default-direction}: auto;
    margin-#{$opposite-direction}: auto;
    float: none;
    p {
      margin: 0;
    }
    @include breakpoint(medium) {
      max-width: none;
    }
    &.maxWidth-medium {
      max-width: 760px;
    }
  }
  &.with-border {
    .article-item {
      border-top: 1px solid $gainsboro;
      padding-top: 2rem;
      &:first-child {
        border: 0;
        padding-top: 0;
      }
    }
  }
}

.topper-main-category {
  color: $primary-color;
  //text-transform: uppercase;
  a {
    color: $primary-color;
    display: inline-block;
    &:hover {
      color: $black;
    }
  }
}

.articles-list {
  margin-bottom: 2rem;
  .separator-field {
    color: $primary-color;
  }
  .description-teaser {
    position: absolute;
    bottom: 0;
    #{$default-direction}: 0;
    background: rgba(0, 0, 0, 0.6);
    padding: 1rem 1rem 0 1rem;
    h4 {
      font-size: 1.25rem;
    }
    .topper-main-category {
      a {
        &:hover {
          color: $white;
        }
      }
    }
    .article-title {
      h3 {
        a {
          display: block;
          color: $white;
          &:hover {
            color: $primary-color;
          }
        }
      }
    }
  }
}

.article-item {
  position: relative;
  margin-bottom: 2rem;
  .articles-grid & {
    max-width: 360px;
    margin-#{$default-direction}: auto;
    margin-#{$opposite-direction}: auto;
  }
}

.article-info {
  position: relative;
  h4 {
    font-size: 1.25rem;
  }
}

.article-img {
  margin-bottom: 0.25rem;
  text-align: center;
  position: relative;
  .articles-grid & {
    margin-bottom: 0.5rem;
  }
  a {
    display: block;
    img {
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
      transition: box-shadow 200ms ease-out;
    }
    &:hover,
    &:focus {
      img {
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
        opacity: 0.7;
      }
    }
  }
}

.section-wrapper {
  margin-bottom: 2rem;
}

.block-wrapper {
  margin-bottom: 1.5rem;
  &--top {
    .block-header {
      text-align: #{$default-direction};
      @include breakpoint(small only) {
        text-align: center;
      }
    }
  }
}

.block-header {
  h1,
  h2,
  h3 {
    margin: 0;
  }
  margin-bottom: 1rem;
  text-align: center;
}

.items-slider {
  margin-bottom: 1.5rem;
}

.item-holder {
  position: relative;
  margin-bottom: 1.5rem;
  .items-slider & {
    margin-bottom: 0;
  }
  .item-info {
    font-family: Arial, Helvetica, sans-serif;
    font-size: rem-calc(15);
    background: rgba(0, 0, 0, 0.6);
    padding: 0.5rem;
    position: absolute;
    bottom: 0;
    a {
      display: block;
      color: $white;
    }
  }
}

.counter {
  position: relative;
  counter-reset: counter;
  .media-object {
    padding-#{$default-direction}: 40px;
    &::before {
      counter-increment: counter;
      content: counter(counter) ". ";
      position: absolute;
      #{$default-direction}: 0;
      width: 40px;
      font-size: 45px;
      line-height: 0.5;
    }

    &-section {
      @include breakpoint(small only) {
        text-align: #{$default-direction};
      }
    }
  }
}

.instagram-area {
  margin-bottom: 1.5rem;
}

.poll-area {
  margin-bottom: 1.5rem;
}

.newsletter-area {
  margin-bottom: 1.5rem;
  overflow: hidden;
}

.social-media {
  list-style: none;
  padding: 29px 0 0;
  a {
    display: inline-block;
    vertical-align: middle;
    margin: 0 2px;
    width: 38px;
    height: 38px;
    line-height: 38px;
    background-color: $gray;
    border-radius: 50%;
    color: $white;
    font-size: 1.125rem;
    text-align: center;
    &:hover {
      background-color: $primary-color;
    }
    span {
      display: block;
      margin: 0 -50%;
      line-height: 1.5;
      font-family: $font-alt;
      font-size: rem-calc(12);
      text-transform: uppercase;
      color: $black;
    }
    i {
    }
  }
  .block-wrapper & {
    margin: 0 0 2.5rem;
    padding: 0;
    a {
      margin: 0 1rem;
      width: 50px;
      height: 50px;
      line-height: 50px;
      background-color: $black;
      font-size: 1.5rem;
      &:hover {
        background-color: $primary-color;
      }
    }
  }
  .entry-sharing & {
    margin: 0 0 1.5rem;
    padding: 0;
    a {
      margin: 0 0.5rem;
      width: 58px;
      height: 58px;
      line-height: 58px;
      background-color: $black;
      font-size: 2rem;
      &:hover {
        background-color: $primary-color !important;
      }
    }
  }
}

.envelope-btn-bg {
  background-color: $envelope !important;
}

.rss-btn-bg {
  background-color: $rss !important;
}

.youtube-btn-bg {
  background-color: $youtube !important;
}

.gplus-btn-bg {
  background-color: $gplus !important;
}

.twitter-btn-bg {
  background-color: $twitter !important;
}

.facebook-btn-bg {
  background-color: $facebook !important;
}

.instagram-btn-bg {
  background-color: $instagram !important;
}

.linkedin-btn-bg {
  background-color: $linkedin !important;
}

.pinterest-btn-bg {
  background-color: $pinterest !important;
}

.footer-menu {
  ul {
    list-style: none;
    text-align: center;
    li {
      display: inline-block;

      a {
        color: $medium-gray;
        font-family: $font-alt;
        font-size: rem-calc(13);
        border-#{$opposite-direction}: 1px solid $medium-gray;
        padding: 0 20px;
        line-height: 1rem;
        text-transform: uppercase;
        letter-spacing: 3px;
        &:hover {
          color: $primary-color;
        }
      }
      &:last-child {
        a {
          border-#{$opposite-direction}: none;
        }
      }
      &.active {
        a {
          color: $primary-color;
        }
      }
    }
  }
}

.footer-extra {
  font-size: 11px;
}

.copyright {
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: rem-calc(14);
  /*@include breakpoint(1150px) {
    text-align: #{$opposite-direction};
    margin-bottom: auto;
  }*/
  img {
    margin-bottom: 1.5rem;
  }
  span {
    display: block;
    font-family: Arial, Helvetica, sans-serif;
  }
}

.magazines-list {
  text-align: center;
  font-size: 0;
  a {
    width: 50%;
    text-align: center;
    display: inline-block;
  }
  @include breakpoint(480px) {
    a {
      width: auto;
      margin: 0 0.5rem;
    }
  }
  @include breakpoint(large) {
    text-align: #{$default-direction};
    a {
      margin: 0 1rem;
    }
  }
}

.entry-header {
  margin-bottom: 1rem;
}

.entry-meta {
  h4 {
    margin: 0;
  }
}

.entry-title {
  h1 {
    font-family: $font-bold;
    margin: 0 0 0.25rem;
    //text-transform: uppercase;
  }
}

.entry-subtitle {
  h4,
  h5,
  h6 {
    margin: 0;
    color: $medium-gray;
  }
}

#main-wrap {
  .entry-content {
    font-family: Arial, $font-alt;
    font-size: 15px;
    p {
      font-family: inherit;
      font-size: inherit;
      margin-bottom: 1rem;
      text-align: #{$default-direction};
    }
    a {
      color: $primary-color;
    }
  }
}

.img-field {
  margin-bottom: 1rem;
  img {
    margin-bottom: 0.5rem;
  }
  h6 {
    text-align: #{$default-direction};
    margin: 0;
    font-size: rem-calc(15);
    color: $medium-gray;
  }
}

.entry-footer {
  margin-bottom: 1rem;
}

.by-author {
  text-transform: uppercase;
  color: $black;
  letter-spacing: 1px;
}

.entry-tags {
  margin-bottom: 2rem;
  a {
    font-family: $font-alt;
    font-size: 13px;
    display: inline-block;
    line-height: 1;
    border-#{$default-direction}: 1px solid #5d5d5d;
    margin-#{$default-direction}: 2px;
    padding-#{$default-direction}: 6px;
    color: #5d5d5d;
    &:first-child {
      margin-#{$default-direction}: 0;
      padding-#{$default-direction}: 0;
      border-#{$default-direction}: 0;
    }
    &:hover {
      color: $primary-color;
    }
  }
  &.recipe-tags {
    padding: 1rem 0;
    border-top: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
    span {
      margin-#{$opposite-direction}: 0.25rem;
      color: $primary-color;
      font-size: 1.25rem;
    }
    a {
      color: $primary-color;
      font-size: 1rem;
      border: 0;
      margin: 0;
      padding: 0;
      &:after {
        content: ", ";
      }
      &:hover {
        color: $secondary-color;
      }
    }
  }
}

.entry-sharing {
  margin-bottom: 3.5rem;
}

.breadcrumbs-wrapper {
  padding-top: 0.5rem;
  li {
    font-family: $font-alt;
    text-transform: capitalize;
  }
  .breadcrumbs li:not(:last-child):after {
    content: ">";
  }
}

.page-title {
  margin-bottom: 1rem;
  h1,
  h2 {
    margin: 0;
    line-height: 1;
  }
  span {
    color: $primary-color;
  }
}

.txt-field {
  font-family: $font-alt;
  font-size: 15px;
  p {
    font-family: inherit;
    font-size: inherit;
  }
}

.results-count {
  margin-bottom: 1rem;
  color: $gray;
}

.begin-slideshow {
  background-color: $black;
  font-size: 1.5rem;
  padding: 1rem;
  margin-bottom: 2rem;
  span {
    display: block;
    padding: 0.25rem 0;
    font-size: 22px;
  }
  .feature-img & {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    #{$opposite-direction}: 0;
  }
}

.feature-img {
  position: relative;
}

#main-wrap {
  .media-slider-intro {
    font-family: Arial, $font-alt;
    p {
      font-family: inherit;
      font-size: 15px;
    }
    a {
      color: $primary-color;
      &:hover {
        color: $white;
      }
    }
  }
}

.media-slider {
  display: none;
  margin: 0 0 2rem;
  -moz-user-select: auto;
  .slider-item-img {
    text-align: center;
  }
  img {
    display: inline-block;
  }
  .slick-prev,
  .slick-next {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
  }
  #main-wrap & {
    p {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 15px;
    }
  }
}

.slider-counter {
  padding: 1rem 0;
  text-align: center;
  font-size: 2rem;
}

.slider-item-txt {
  font-family: $font-alt;
  font-size: 15px;
  p {
    font-size: inherit;
  }
  h5,
  h6 {
    //font-family: $font-alt-bold;
  }
}

.error-txt {
  padding: 2rem 0;
  font-family: $font-bold;
  font-size: 38px;
  text-align: center;
  line-height: 1;
  margin-bottom: 1.5rem;
  @include breakpoint(medium) {
    font-size: 75px;
  }
}

.searchbox-holder {
  margin-bottom: 1.5rem;
  .input-group {
    max-width: 480px;
    margin: 0 auto;
  }
  p {
    font-family: $font-alt;
    font-size: 17px;
    text-align: center;
  }
  .button {
    font-family: $font-alt;
    &:hover {
      background-color: $primary-color;
    }
  }
}

.loader-area {
  position: relative;
  //height: 400px;
  .media-slider {
    opacity: 0;
  }
  &:after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    #{$default-direction}: 0;
    #{$opposite-direction}: 0;
    width: 100%;
    height: 100%;
    background: #ffffff url(../img/ajax-loader.gif) no-repeat center 100px;
  }
}

.newsletter-area {
  #mc_embed_signup {
    font-family: Arial, Helvetica, sans-serif;
    color: #383838;
  }
  #mc_embed_signup form {
    padding: 0;
  }
  #mc_embed_signup p {
    font-size: 1rem;
    text-align: center;
  }
  #mc_embed_signup h2 {
    margin: 0 0 1rem;
    text-transform: capitalize;
    font-weight: normal;
    font-size: 2rem;
    text-decoration: underline;
    text-align: center;
  }
  #mc_embed_signup .button {
    background: $secondary-color;
    border: 0;
    border-radius: 0;
    color: $white;
    font-size: 30px;
    position: relative;
    text-align: center;
    display: block;
    width: 100%;
    height: auto;
    line-height: normal;
    margin: 0 0 1rem;
    padding: 0.25rem 0;
    &:hover {
      background-color: $primary-color;
    }
  }
  #mc_embed_signup .mc-field-group {
    width: 100%;
  }
  #mc_embed_signup .mc-field-group input {
    background: $white-smoke;
    border: 1px solid #f0f0f0;
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    font-size: 1rem;
  }
  .privacy-field {
    text-align: center;
    font-size: 13px;
    a {
      font-weight: bold;
      text-transform: capitalize;
      text-decoration: underline;
      color: $black;
      &:hover {
        color: $primary-color;
      }
    }
  }
}

.leading-women-logo {
  img {
    max-height: 300px;
    @include breakpoint(medium) {
      max-height: 360px;
    }
  }
}

.leading-women-content {
  margin-bottom: 2rem;
  h1 {
    font-size: 2rem;
    //text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 0;
    line-height: 0.75;
  }
  .subheader {
    font-family: Arial, Helvetica, sans-serif;
    margin: 0 0 1rem;
    //text-transform: uppercase;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.2;
    margin-top: 0.5rem;
  }
  @include breakpoint(medium) {
    h1 {
      font-size: 4rem;
    }
    .subheader {
      font-size: 1.1rem;
    }
  }
  @include breakpoint(1100px) {
    h1 {
      font-size: 5rem;
    }
    .subheader {
      font-size: 1.4rem;
    }
  }
}

.select-field {
  label {
    font-size: 1rem;
    font-family: Arial, Helvetica, sans-serif;
  }
}

.search-filter {
  margin-bottom: 1.5rem;
  .btns-area {
    padding-top: 0.5rem;
    text-align: center;
    .button {
      max-width: 100%;
      text-align: center;
      font-size: 100%;
      height: 2.625rem;
    }
    @include breakpoint(medium) {
      padding-top: 1.75rem;
      text-align: #{$default-direction};
    }
  }
}

.filter-tabs {
  font-family: $font-alt;
  font-size: 14px;
  padding-top: 0.75rem;
  margin-bottom: 1rem;
  span {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 1rem;
    margin-#{$opposite-direction}: 1rem;
  }
  a.button {
    font-family: $font-alt;
    vertical-align: middle;
    background-color: #b6b6b6;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    padding: 0.5em 1em;
    margin: 0 0.15rem 1rem;
    &.active,
    &.selected,
    &:hover {
      background-color: $primary-color;
    }
  }
  label.button {
    font-family: $font-alt;
    vertical-align: middle;
    background-color: #b6b6b6;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    padding: 0.5em 1em;
    margin: 0 0.15rem 1rem;
    &.active,
    &.selected,
    &:hover {
      background-color: $primary-color;
    }
  }
}

.recipe-block-info {
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid $light-gray;
  font-family: $font-alt;
  font-size: 1rem;
  h3 {
    margin-bottom: 1.125rem;
    font-family: $font-alt;
  }
  h4 {
    font-family: $font-alt;
  }
  #main-wrap & p {
    font-size: 1rem;
  }
  a {
    color: $primary-color;
    &:hover {
      text-decoration: underline;
    }
  }
}

.next-recipe {
  padding: 15px;
  background-color: #eeeeee;
}

.see-more-recipe {
  font-family: $font-alt;
  font-size: 14px;
  margin-bottom: 1rem;
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

.recipe-main-slider {
  background-color: #eeeeee;
  .flex-video,
  iframe {
    margin: 0;
  }
  .slider-item {
    vertical-align: top;
    position: relative;
  }
}

.slider-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  #{$default-direction}: 0;
  #{$opposite-direction}: 0;
  background-color: rgba(0, 0, 0, 0.6);
  color: $white;
  padding: 0.5rem;
}

.recipe-thumbs-slider-wrapper {
  background-color: #eeeeee;
}

.recipe-info-icons {
  padding: 13px 1rem;
  text-align: #{$opposite-direction};
  > div {
    display: inline-block;
    vertical-align: middle;
    text-align: #{$opposite-direction};
    margin-#{$default-direction}: 0.5rem;
    color: #828282;
    span {
      display: inline-block;
      vertical-align: middle;
    }
    i {
      display: inline-block;
      vertical-align: middle;
      font-size: 1.25rem;
      margin-#{$default-direction}: 0.1rem;
    }
  }
}

.thumbs-slider-item {
  vertical-align: top;
  padding-#{$opposite-direction}: 1px;
}

ul.nutritionData {
  list-style: none;
  list-style: none;
  padding: 0;
  margin: 1rem 0 0.25rem;
}

.nutritionData li {
  border: 1px solid #bbb;
  //float: #{$default-direction};
  display: inline-block;
  padding: 0.3rem;
  margin: 0 0.2rem 0.5rem;
  min-width: 65px;
}

.nutritionData li a {
  color: $secondary-color;
  text-transform: capitalize;
  &:hover {
    text-decoration: none;
    color: $primary-color;
  }
}

.nutritionData li hr {
  margin: 0.2rem auto;
}

.nutritionData li > div > div {
  font-size: 12px;
}

.nutritionData .full-border {
  margin-#{$opposite-direction}: 1rem;
}

/*popover*/
.popover__wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.popover__title > div {
  margin-bottom: 0.2rem;
  font-size: 12px;
}

.popover__title span {
  font-family: $font-alt;
  font-size: 1rem;
}

.popover__content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  transform: translate(0, 10px);
  background-color: #fff;
  padding: 1.5rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  border: 1px solid #c2c2c2;
  /*#{$default-direction}: -124px;*/
  #{$default-direction}: -6rem;
  width: 15em;
}

.popover__content p,
.popover__content h4 {
  color: #000;
}

.popover__content h4 span.border-bottom {
  border-bottom: 2px solid #bababa;
}

.popover__content:before {
  position: absolute;
  z-index: -1;
  content: "";
  #{$opposite-direction}: calc(50% - 10px);
  top: -9px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #fff transparent;
  transition-duration: 0.3s;
  transition-property: transform;
}

.popover__content:after {
  position: absolute;
  z-index: -2;
  content: "";
  #{$opposite-direction}: calc(50% - 10px);
  top: -10px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #c2c2c2 transparent;
  transition-duration: 0.3s;
  transition-property: transform;
}

.popover__wrapper:hover .popover__content {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transform: translate(0, -20px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}

.popover__message {
  text-align: center;
}

ul.nutritionData li:first-child .popover__content {
  #{$default-direction}: 0px;
}

ul.nutritionData li:first-child .popover__content:after,
ul.nutritionData li:first-child .popover__content:before {
  #{$default-direction}: 15px;
  #{$opposite-direction}: auto;
}

ul.nutritionData li:last-child .popover__content {
  #{$opposite-direction}: 0px;
  #{$default-direction}: auto;
}

ul.nutritionData li:last-child .popover__content:after,
ul.nutritionData li:last-child .popover__content:before {
  #{$opposite-direction}: 15px;
  #{$default-direction}: auto;
}

/*end popover*/

@media screen and (max-width: 30em) {
  .popover__wrapper:hover .popover__content,
  ul.nutritionData li:last-child .popover__content,
  ul.nutritionData li:first-child .popover__content {
    #{$default-direction}: 30px;
    position: fixed;
  }

  .popover__content:after,
  .popover__content:before {
    display: none;
  }
  .popover__content {
    width: calc(100vw - 60px);
  }

  .popover__title:after {
    position: absolute;
    z-index: 2;
    content: "";
    #{$opposite-direction}: calc(50% - 10px);
    /* top: 61%; */
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
    transition-duration: 0.3s;
    transition-property: transform;
    transform: translate(0, -7px);
    opacity: 0;
  }
  .popover__title:before {
    position: absolute;
    z-index: 1;
    content: "";
    #{$opposite-direction}: calc(50% - 10px);
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #c2c2c2 transparent;
    transition-duration: 0.3s;
    transition-property: transform;
    transform: translate(0, 45px);
    opacity: 0;
  }
  .popover__wrapper:hover .popover__title:before,
  .popover__wrapper:hover .popover__title:after {
    opacity: 1;
    transition-duration: 0.5s;
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
  }
}
#views-exposed-form-food-block-1 .views-exposed-form {
  display: none;
}
