$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts" !default;

$icon-timer: "\e910";
$icon-clock: "\e910";
$icon-snapchat: "\e90e";
$icon-youtube: "\e900";
$icon-stats-growth: "\e911";
$icon-stats-bars: "\e912";
$icon-pie-chart: "\e913";
$icon-tag: "\e914";
$icon-whatsapp: "\e90f";
$icon-close: "\e90d";
$icon-arrow-right: "\e901";
$icon-arrow-down: "\e902";
$icon-arrow-left: "\e903";
$icon-arrow-up: "\e904";
$icon-magnifier: "\e905";
$icon-circle: "\e906";
$icon-pinterest: "\e907";
$icon-linkedin: "\e908";
$icon-google-plus: "\e909";
$icon-instagram: "\e90a";
$icon-twitter: "\e90b";
$icon-facebook: "\e90c";

